import { Link } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import Ankle from './Ankle';
import Footer from './Footer';
import './MultiItemTemplate.scss';
import SectionHeader from './SectionHeader';


SwiperCore.use([Navigation, Pagination]);

const MultiItemTemplate = ({imageNames, name, children, items, path, title, noun}) => {
  const [showMobileItems, setShowMobileItems] = useState(false)
  const titleRef = useRef()

  console.log("Children are", children)

  useEffect(() => { 
    const fromMenuClick=new URLSearchParams(window.location.search).get("scroll") === "true"
    if (fromMenuClick) {
      titleRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, []); 

  const imageSlides = imageNames.map((name, index) => {
    return <SwiperSlide key={index}><img className="item-image" src={`/assets${name}`} alt={name} /></SwiperSlide>
  })

  const menuItems = items.map((item, index) => {
    const style = item.text === name ? 'menu-item selected' : 'menu-item';
    return <Link className={style} key={index} to={`${path}/${item.path}?scroll=true`}> {item.text} </Link>
  })

  const temporary_image = 'texture_light_blue.jpg'

  const style = {
    backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 66%, rgba(0,0,0,0.9) 100%), url(/assets/${temporary_image})`,
  };

  const moreItemsButtonClass = showMobileItems ? 'mobile-moreitems open' : 'mobile-moreitems';

  return (
    <div className='MultiItemTemplate'>
      <SectionHeader >
      </SectionHeader>
      <div className='desktop-width-guard'>
      {/* <BreadCrumbs detailTitle={detailTitle} topicTitle={topicTitle} sectionTitle={sectionTitle} path={path} /> */}
        <div className="header short" style={style}>
          <div className="headers">
            <h1>{title}</h1>
          </div>
        </div>
        <div className="top">
          <div className="left">
            <div className='item-title'>
              <h2 ref={titleRef}>{name}</h2>
              <div 
                className={moreItemsButtonClass} 
                role="button" 
                tabIndex="0" 
                onClick={()=>setShowMobileItems(!showMobileItems)}
                onKeyPress={()=>setShowMobileItems(!showMobileItems)}
              >
                {`MORE ${noun}s`}
              </div>
            </div>
            <Swiper
              centeredSlides={true}
              navigation={{
                "clickable": true}}
              pagination={{
                "clickable": true}}

            >
              {imageSlides}
            </Swiper>
          </div>
          {showMobileItems && <div className="bc-menu"> {menuItems} </div>}
          <div className="bc-menu desktop"> {menuItems} </div>
        </div>
        <div className='desktop-styling'>
          <div className="crib">
              {children}
          </div>
          <Ankle showThirdPanel={true}>
          </Ankle>
        </div>
      </div>
      <Footer>
      </Footer>
    </div>
  )
}

export default MultiItemTemplate