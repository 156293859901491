import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from 'react'
import { Helmet } from "react-helmet"
import Accordion from '../components/Accordion'
import Carousel from '../components/Carousel'
import FactBox from '../components/FactBox'
import MultiItemTemplate from '../components/MultiItemTemplate'
import Slide from '../components/Slide'

function MethodTemplate({pageContext}) {
  const shortcodes = { Accordion, FactBox, Carousel, Slide }
  const { method } = pageContext

  const items = [
    {text: 'Internal Condom', path: 'internal-condom'},
    {text: 'External Condom', path: 'male-condom'},
    {text: 'Diaphragm', path: 'diaphragm'},
    {text: 'The Pill', path: 'pill'},
    {text: 'The Patch', path: 'patch'},
    {text: 'The Ring', path: 'ring'},
    {text: 'The Shot', path: 'shot'},
    {text: 'The Implant', path: 'implant'},
    {text: 'Copper IUD', path: 'copper-iud'},
    {text: 'Hormonal IUD', path: 'hormonal-iud'},
    {text: 'Sterilization', path: 'sterilization'}
  ]

  console.log("Method is", method)

  return (
    <div className="BirthControlImplant">
      <Helmet>
        <title>{method.frontmatter.title}</title>
        <meta name="description" content={method.frontmatter.title} />
      </Helmet>
      <MultiItemTemplate
        imageNames={method.frontmatter.images}
        name={method.frontmatter.title}
        items={items}
        path="/birth-control/methods"
        title="Know your Birth Control"
        noun="METHOD"
      >
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {method.body}
          </MDXRenderer>
        </MDXProvider>
      </MultiItemTemplate>
    </div>
  )
}

export default MethodTemplate